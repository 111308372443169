<template>
  <div>
    <title-bar :title="$t('navigations.employees')"/>
    <columns-toggle
        :columns-array="defaultColumns"
        :action="showColumnsModal"
        :page="page"
        @clearAction="showColumnsModal = false"
        @init="beforeSetColumns"
        @columnsChange="setColumns"
    />
    <div v-if="rows" class="custom-table">
      <advanced-filter
          :page-name="page"
          :fields-array="filterFields"
          :action="showFilterModal"
          @clearAction="showFilterModal = false"
          @paramsChange="setAdvancedParams"
      />
      <table-actions
          :actions="tableActions"
          :addBtnText="$t('messages.employeeCreate')"
          :addBtnLink="$helper.getAddUrl('employees')"
          :actionBtnText="$t('messages.bulkPrintEmployeeCards')"
          :actionBtnDisabled="actionBtnDisabled"
          actionBtnIcon="printer"
          :entries="pages"
          @onSearchInput="inputChange"
          @perPageChange="perPageChange"
          @columnsChange="showColumnsModal = true"
          @filterChange="showFilterModal = true"
          @actionBtnClicked="bulkPrintSubmitted"
          :defaultPerPage="perPage"
      />
      <table-lite
          :is-slot-mode="true"
          :has-checkbox="true"
          :is-loading="isLoading"
          :columns="columns"
          :rows="rows"
          :sortable="sortable"
          @do-search="setSort"
          @is-finished="isLoading = false"
          @return-checked-rows="updateCheckedRows"
      >
        <template v-slot:number="data">
          <div v-if="data.value.number">
            {{ data.value.number }}
          </div>
        </template>
        <template v-slot:file="data">
          <zoom-on-hover-image v-if="data.value.file && data.value.file.url" :src="data.value.file.url"></zoom-on-hover-image>
        </template>
        <template v-slot:familyName="data">
          <div v-if="data.value.givenName && data.value.familyName">
            {{ data.value.givenName + " " + data.value.familyName }}
          </div>
        </template>
        <template v-slot:contract="data">
          <div v-if="data.value.contract">
            {{ data.value.contract.name }}
          </div>
        </template>
        <template v-slot:department="data">
          <div v-if="data.value.department">
            {{ data.value.department.name }}
          </div>
        </template>
        <template v-slot:itemCategories="data">
          <div v-if="data.value.itemCategories && data.value.itemCategories.length > 0">
              <span v-for="(itemCategory, index) in data.value.itemCategories" :key="itemCategory">
                {{ $helper.getEnumTranslation("ITEM_CATEGORY", itemCategory, this.$i18n.locale) }}
                <span v-if="index !== data.value.itemCategories.length - 1">, </span>
              </span>
          </div>
        </template>
        <template v-slot:employeeType="data">
          <div v-if="data.value.employeeType">
            {{ data.value.employeeType.name }}
          </div>
        </template>
        <template v-slot:tshirtSize="data">
          <div v-if="data.value.tshirtSize">
            {{ data.value.tshirtSize.name }}
          </div>
        </template>
        <template v-slot:job="data">
          <div v-if="data.value.job">
            {{ data.value.job.name }}
          </div>
        </template>
        <template v-slot:phone="data">
          {{ data.value.businessPhone }}<br>
          {{ data.value.telephone }}
        </template>
        <template v-slot:address="data">
          {{ $helper.truncate(data.value.address.streetAddress+', '+data.value.address.city) }}
        </template>
        <template v-slot:employmentDate="data">
          {{ $helper.formatDate(data.value.employmentDate) }}
        </template>
        <template v-slot:trialPeriod="data">
          <div v-if="data.value.inTrial">
            <div v-if="data.value.trialsDaysLeft === 1">{{ data.value.trialsDaysLeft }} {{ $t('forms.day') }}</div>
            <div v-else-if="data.value.trialsDaysLeft > 1 && data.value.trialsDaysLeft < 5">{{ data.value.trialsDaysLeft }} {{ $t('forms.2days') }}</div>
            <div v-else>{{ data.value.trialsDaysLeft }} {{ $t('forms.days') }}</div>
          </div>
          <div v-else>{{ $t('forms.trialPassed') }}</div>
        </template>
        <template v-slot:shift="data">
          <div v-if="data.value.shift">
            {{ data.value.shift.name }}
          </div>
        </template>
        <template v-slot:skillMatricesSum="data">
          <div v-if="data.value.skillMatricesSum">
            {{ data.value.skillMatricesSum }}
          </div>
        </template>
        <template v-slot:skillMatricesScore="data">
          <div v-if="data.value.skillMatricesScore">
            {{ data.value.skillMatricesScore }}
          </div>
        </template>
        <template v-slot:printStatus="data">
          <b-badge :variant="data.value.printStatus == 'TO_PRINT' ? 'danger' : 'success'">
            {{ $helper.getEnumTranslation("PRINT_STATUS", data.value.printStatus, $i18n.locale) }}
          </b-badge>
        </template>
        <template v-slot:status="data">
          <b-badge v-if="data.value.status" :variant="data.value.status.name === 'Active' ? 'success' : 'danger'">{{ data.value.status.name }}</b-badge>
        </template>
        <template v-slot:actions="data">
          <div class="just-buttons">
            <edit-btn
                v-if="canUpdate"
                :path="$helper.getEditUrl('employees', data.value.id)"
            />
            <view-btn
                v-if="canView"
                :path="$helper.getViewUrl('employees', data.value.id)"
            />
            <delete-btn
                v-if="canDelete"
                @pressDelete="deleteEmployee(data.value['@id'])"
            />
          </div>
        </template>
      </table-lite>
      <table-pagination
          v-model="currentPage"
          :total="pages"
          :per-page="perPage"
          @input="onChangePage"
      />
    </div>
  </div>
</template>

<script>

import TitleBar from "@/components/TitleBar.vue";
import TableLite from "vue3-table-lite";
import TablePagination from "@/components/Table/TablePagination.vue";
import TableActions from "@/components/Table/TableActions.vue";
import EditBtn from "@/components/Buttons/EditBtn.vue";
import DeleteBtn from "@/components/Buttons/DeleteBtn.vue";
import ResourceUtils from "@/mixins/ResourceUtils";
import AdvancedFilter from "@/components/Table/AdvancedFilter.vue";
import ColumnsToggle from "@/components/Table/ColumnsToggle.vue";
import {useToast} from "vue-toastification";
import Toast from "@/components/Toast.vue";
import ViewBtn from "@/components/Buttons/ViewBtn.vue";
import ListUtils from "@/mixins/ListUtils";
import TableLiteUtils from "@/mixins/TableLiteUtils";
import ZoomOnHoverImage from "@/components/ZoomOnHoverImage";

export default {
  name: "AdminHolidayListing",
  components: {
    ViewBtn,
    TableLite,
    TablePagination,
    TableActions,
    EditBtn,
    DeleteBtn,
    TitleBar,
    AdvancedFilter,
    ColumnsToggle,
    ZoomOnHoverImage
  },
  mixins: [ResourceUtils, ListUtils, TableLiteUtils],
  data() {
    return {
      search: "",
      page: 'employees',
      isLoading: false,
      actionBtnDisabled: true,
      rows: null,
      perPage: this.$store.state.defaultPerPage,
      currentPage: 1,
      pages: 0,
      enabled: true,
      checkedRows: [],
      showFilterModal: false,
      canView: false,
      canUpdate: false,
      canDelete: false,
      tableActions: [ 'search', 'actionBtn', 'perPage', 'advancedFilter', 'columnsBtn'],
      showColumnsModal: false,
      defaultStatuses: [],
      filterFields: [
        {
          label: "forms.status",
          name: "status",
          value: null,
          multiple: true,
          type: "customSelect",
          resource: this.$EntityOptions,
          resourceType: "entityOption",
          entityType: "STATUS"
        },
        {
          label: "forms.number",
          name: "number",
          value: null,
          type: "text",
        },
        {
          label: "forms.email",
          name: "email",
          value: null,
          type: "text",
        },
        {
          label: "forms.personalId",
          name: "personalId",
          value: null,
          type: "text",
        },
        {
          label: "forms.telephone",
          name: "telephone",
          value: null,
          type: "text",
        },
        {
          label: "forms.businessPhone",
          name: "businessPhone",
          value: null,
          type: "text",
        },
        {
          label: "forms.givenName",
          name: "givenName",
          value: null,
          type: "text",
        },
        {
          label: "forms.familyName",
          name: "familyName",
          value: null,
          type: "text",
        },
        {
          label: "forms.title",
          name: "title",
          value: null,
          type: "text",
        },
        {
          label: "forms.category",
          name: "category",
          value: null,
          type: "text",
        },
        {
          label: "forms.country",
          name: "address.country",
          value: null,
          type: "text",
        },
        {
          label: "forms.city",
          name: "address.city",
          value: null,
          type: "text",
        },
        {
          label: "forms.contract",
          name: "contract",
          value: null,
          type: "customSelect",
          resource: this.$EntityOptions,
          resourceType: "entityOption",
          entityType: "CONTRACT"
        },
        {
          label: "forms.job",
          name: "contract",
          value: null,
          type: "customSelect",
          resource: this.$EntityOptions,
          resourceType: "entityOption",
          entityType: "JOB"
        },
        {
          label: "forms.printStatus",
          name: "printStatus",
          value: null,
          type: "customSelect",
          resource: this.$Enums,
          resourceType: "enum",
          enumName: 'PRINT_STATUS'
        },
        {
          label: "forms.shift",
          name: "shift",
          value: null,
          lazyLoad: false,
          type: "customSelect",
          resource: this.$EntityOptions,
          resourceType: "entityOption",
          entityType: "SHIFT"
        },
        {
          label: "forms.education",
          name: "education",
          value: null,
          lazyLoad: false,
          type: "customSelect",
          resource: this.$Enums,
          resourceType: "enum",
          enumName: 'EDUCATION_LEVEL'
        },
        {
          label: "forms.tshirtSize",
          name: "tshirtSize",
          value: null,
          type: "customSelect",
          resource: this.$EntityOptions,
          resourceType: "entityOption",
          entityType: "TSHIRT_SIZE"
        },
        {
          label: "forms.tshirt",
          name: "tshirt",
          value: null,
          type: "text",
        },
        {
          label: "forms.longTshirt",
          name: "longTshirt",
          value: null,
          type: "text",
        },
        {
          label: "forms.vest",
          name: "vest",
          value: null,
          type: "text",
        },
        {
          label: "forms.shoeSize",
          name: "shoeSize",
          value: null,
          type: "text",
        },
        {
          label: "forms.workShoes",
          name: "workShoes",
          value: null,
          type: "text",
        },
        {
          label: "forms.bag",
          name: "workShoes",
          value: null,
          type: "text",
        },
      ],
      firstLoad: true,
      advancedParams: [],
      sortable: {
        order: "familyName",
        sort: "asc",
      },
      columns: [],
      defaultColumns: [
        {
          label: this.$t("forms.id"),
          field: "id",
          sortable: true,
          enabled: true,
          isKey: true,
        },
        {
          label: this.$t("forms.photo"),
          field: "file",
          sortable: false,
          enabled: true
        },
        {
          label: this.$t("forms.number"),
          field: "number",
          sortable: true,
          enabled: true
        },
        {
          label: this.$t("forms.title"),
          field: "title",
          sortable: true,
          enabled: false
        },
        {
          label: this.$t("forms.department"),
          field: "department",
          sortable: true,
          enabled: false
        },
        {
          label: this.$t("forms.itemCategoryDepartment"),
          field: "itemCategories",
          sortable: false,
          enabled: false
        },
        {
          label: this.$t("forms.employeeType"),
          field: "employeeType",
          sortable: true,
          enabled: false
        },
        {
          label: this.$t("forms.replacement"),
          field: "replacement",
          sortable: true,
          enabled: false
        },
        {
          label: this.$t("forms.name"),
          field: "familyName",
          sortable: true,
          enabled: true
        },
        {
          label: this.$t("forms.contract"),
          field: "contract",
          sortable: false,
          enabled: true
        },
        {
          label: this.$t("forms.jobTitle"),
          field: "job",
          sortable: false,
          enabled: true
        },
        {
          label: this.$t("forms.status"),
          field: "status",
          sortable: true,
          enabled: true
        },
        {
          label: this.$t("forms.address"),
          field: "address",
          sortable: true,
          enabled: false
        },
        {
          label: this.$t("forms.phone"),
          field: "telephone",
          sortable: true,
          enabled: false
        },
        {
          label: this.$t("forms.tshirt"),
          field: "tshirt",
          sortable: true,
          enabled: false
        },
        {
          label: this.$t("forms.tshirtSize"),
          field: "tshirtSize",
          sortable: true,
          enabled: false
        },
        {
          label: this.$t("forms.longTshirt"),
          field: "longTshirt",
          sortable: true,
          enabled: false
        },
        {
          label: this.$t("forms.vest"),
          field: "vest",
          sortable: true,
          enabled: false
        },
        {
          label: this.$t("forms.shoeSize"),
          field: "shoeSize",
          sortable: true,
          enabled: false
        },
        {
          label: this.$t("forms.workShoes"),
          field: "workShoes",
          sortable: true,
          enabled: false
        },
        {
          label: this.$t("forms.bag"),
          field: "bag",
          sortable: true,
          enabled: false
        },
        {
          label: this.$t("forms.employmentDate"),
          field: "employmentDate",
          sortable: true,
          enabled: false
        },
        {
          label: this.$t("forms.shift"),
          field: "shift",
          sortable: true,
          enabled: true
        },
        {
          label: this.$t("forms.printStatus"),
          field: "printStatus",
          sortable: true,
          enabled: false
        },
        {
          label: this.$t("forms.trialPeriod"),
          field: "trialPeriod",
          sortable: true,
          enabled: false,
        },
        {
          label: this.$t("employees.skillMatricesSum"),
          field: "skillMatricesSum",
          sortable: false,
          enabled: false,
        },
        {
          label: this.$t("employees.skillMatricesScore"),
          field: "skillMatricesScore",
          sortable: false,
          enabled: false,
        },
        {
          label: this.$t("forms.actions"),
          field: "actions",
          sortable: false,
          width: "120px",
          enabled: true
        },
      ],
    };
  },
  created() {
    this.setPermissions();
    this.$EntityOptions.getCollection({ params: { type: ['STATUS'], cache: true, ttl: 300 } }).then((response) => {
      response.data["hydra:member"].forEach((status) => {
        if (status.name.includes('Active') || status.name.includes('Beginner')) {
          this.defaultStatuses.push({
            id: status['@id'],
            label: status.name,
            numberId: status.id,
          })
        }
      });

      this.load();
    });
  },
  methods: {
    load() {
      const params = {
        itemsPerPage: this.perPage,
        page: this.currentPage,
        role: "ROLE_EMPLOYEE",
      };
      this.advancedParams.forEach(element => {
        if (element.value !== '' && element.value !== null) {
          params[element.name] = element.value
        }
      });
      if (typeof params.status === 'undefined' && this.defaultStatuses.length) {
        const status = []
        this.defaultStatuses.forEach((st) => {
          status.push(st.id)
        })
        params.status = status;
      }
      params["order[" + this.sortable.order + "]"] = this.sortable.sort;
      if (this.search && this.search.length) {
        params.search = this.search;
        delete params.status
      }

      this.$Users.getCollection({params}, 'employee_list').then((response) => {
        this.pages = response.data["hydra:totalItems"];
        this.rows = response.data["hydra:member"];

        this.firstLoad = false

        // save applied parameters
        this.setTableLiteSettings(this.page, {
          perPage: this.perPage,
          columns: this.columns
        })
      });
    },
    setActionBtnUsability() {
      // bulk print required column ID be showed, this field isKey
      let actionBtnDisabled = true
      this.columns.forEach(function(column) {
        if(column.isKey) {
          actionBtnDisabled = false
        }
      })
      this.actionBtnDisabled = actionBtnDisabled
    },
    setPermissions() {
      this.setActionBtnUsability()

      if (this.$helper.userCapability('CREATE', 'EMPLOYEES')) {
        this.tableActions.push('addBtn')
      }
      this.canView = this.$helper.userCapability('VIEW', 'EMPLOYEES')
      this.canUpdate = this.$helper.userCapability('UPDATE', 'EMPLOYEES')
      this.canDelete = this.$helper.userCapability('DELETE', 'EMPLOYEES')

      this.defaultColumns = this.defaultColumns.filter(function(column) {
        return !((false === this.canUpdate && this.$helper.getGdprSensitiveFields().includes(column.field)))
      }, { canUpdate: this.canUpdate, $helper: this.$helper })
    },
    setColumns(data) {
      this.columns = data
      this.setTableLiteSettings(this.page, { columns: this.columns })
      this.setActionBtnUsability()
    },
    beforeSetColumns() {
      const columns = this.getTableLiteSettingsForPageAndKey(this.page, 'columns')
      if (columns && columns.length > 0) {
        this.defaultColumns.forEach(element => {
          if (columns.includes(element.field)) {
            element.enabled = true
          } else {
            element.enabled = false
          }
        })
      }
    },
    setAdvancedParams(data) {
      this.advancedParams = data
      this.load()
    },
    setSort(offset, limit, order, sort) {
      this.sortable.order = order;
      this.sortable.sort = sort;
      if (order === 'department' || order === 'branch') {
        this.sortable.order = order + '.name';
      }
      if (order === 'trialPeriod') {
        this.sortable.order = 'employmentDate';
      }

      this.load();
    },
    onChangePage(page) {
      this.currentPage = page;
      this.load();
    },
    deleteEmployee(url) {
      this.deleteByUrl(
          this.$Users,
          url,
          this.$t("messages.employeeDeleted"),
          null,
          this.load,
          this.error
      );
    },
    inputChange(value) {
      this.currentPage = 1;
      this.search = value;
      this.load();
    },
    onPageChange() {
      this.load();
    },
    perPageChange(num) {
      this.currentPage = 1;
      this.perPage = num;
      this.load();
    },
    filterSelectChange(enabled) {
      this.enabled = enabled.id
      if (enabled.id === "all") {
        this.enabled = [true, false]
      }
      this.load()
    },
    error() {
      this.$emit("clearAction");
    },
    updateCheckedRows(data) {
      this.checkedRows = [];
      data.forEach((value) => {
        this.checkedRows.push({
          employeeId: value,
          employee: this.rows.find(row => parseInt(row.id) === parseInt(value)),
          index: this.rows.findIndex(row => parseInt(row.id) === parseInt(value)),
        })
      })
    },
    bulkPrintSubmitted() {
      if (this.checkedRows.length === 0) {
        this.$helper.showToast(
            useToast(),
            Toast,
            this.$t('messages.noPdfSelected'),
            this.$t('messages.selectAtLeastOnePdf'),
            "danger"
        );
      }

      if (this.checkedRows.length > 0) {

        const body = {
          resources: this.checkedRows.map((row) => row.employee['@id']),
          name: 'bulk_employee_card',
          templateName: 'BULK_EMPLOYEE_CARD',
        }

        this.$Pdfs
            .createResourceByUrl({ url: `/pdfs/bulk_print`, body } )
            .then((response) => {
              if (response.status === 200) {
                this.printPdf(response.data)
              }
            });
      }
    },
    printPdf(pdf) {
      const link = document.createElement("a");
      link.href = pdf.notCachedUrl;
      link.target = "_blank";
      link.click();
    },
  },
};
</script>

<style lang="scss">
</style>
