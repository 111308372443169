<template>
  <div :class="`zoom-on-hover-image ${cssClass}`" :style="cssProps" @mouseover="enlargeImage" @mouseout="shrinkImage">
    <img :src="src" :alt="alt">
  </div>
</template>

<script>
export default {
  name: 'CallBtn',
  props: {
    src: { type: String, default: '' },
    alt: { type: String, default: '' },
    align: { type: String, default: 'center' },
    width: { type: String, default: '30px' },
    height: { type: String, default: '30px' },
  },
  data() {
    return {
      cssClass: '',
    }
  },
  created() {
    this.cssClass = this.align;
  },
  computed: {
    cssProps() {
      return {
        '--zoom-on-hover-image-width': this.width,
        '--zoom-on-hover-image-height': this.height
      }
    }
  },
  methods: {
    enlargeImage() {
      this.cssClass = `${this.align} zoomed`;
    },
    shrinkImage() {
      this.cssClass = this.align;
    },
  }
}
</script>

<style lang="scss">
@import "../assets/scss/components/ZoomOnHoverImage.scss";
</style>
